$color-white: #ffffff;

:root {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.webgl {
  z-index: -1000;
}

.content {
  position: absolute;
  font-size: 3.6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75rem;
  height: 75rem;
  background-color: rgba($color-white, 0.2);
  z-index: 1;
}
